import { PageComponent } from '@wap-client/constants';
import { isEmpty, trim } from 'lodash';

/*
 ** Filter Allowed Views (Frontend & Temp)
 **
 ** only filtered allowed views for fast frontend development playground
 ** you can set the allowed views list in ".env.development.local" file
 ** example: NEXT_PUBLIC_ALLOWED_VIEWS='gt-slider-hero,gt-plan-your-trip'
 */
export const filterByAllowedViews = (
  views: Record<string, Array<PageComponent>>
): Record<string, Array<PageComponent>> => {
  if (
    !process.env.NEXT_PUBLIC_ALLOWED_VIEWS ||
    isEmpty(process.env.NEXT_PUBLIC_ALLOWED_VIEWS)
  ) {
    return views;
  }

  const allowdViews =
    process.env.NEXT_PUBLIC_ALLOWED_VIEWS.split(',').map(trim);

  const filterChildren = (views: any[]): any[] => {
    return views
      .map((view) => ({
        ...view,
        children: filterChildren(view.children),
      }))
      .filter(
        (view) => allowdViews.includes(view.name) || view.children.length > 0
      );
  };

  const filteredData: any = {};

  for (const key in views) {
    if (views.hasOwnProperty(key)) {
      filteredData[key] = filterChildren(views[key]);
    }
  }

  return filteredData;
};
