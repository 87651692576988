'use client';
import { isArray, isPlainObject } from 'lodash';

interface StorageOptions {
  json?: boolean;
  boolean?: boolean;
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
	params:
		key <string>     : localStorage key to remove
	returns:
		<boolean> : telling if operation succeeded
 */
export function removeStorage(key: string): boolean {
  try {
    localStorage.removeItem(key);
    localStorage.removeItem(key + '_expiresIn');
  } catch (err) {
    console.warn(
      `removeStorage: Error removing key [${key}] from localStorage: ${err}`
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
	params:
		key <string> : localStorage key
		options <StorageOptions> : options for retrieval
	returns:
		<string | null | boolean> : value of localStorage key
		null : in case of expired key or failure
 */
export function getStorage(
  key: string,
  options?: StorageOptions
): string | null | boolean {
  if (typeof window === 'undefined') {
    return null;
  }

  const { json, boolean } = options || {};

  // set expiration for storage
  let expiresIn: any = localStorage.getItem(key + '_expiresIn');

  if (expiresIn) {
    const now = Date.now(); // epoch time, lets deal only with integer

    if (expiresIn === undefined || expiresIn === null) {
      expiresIn = '0';
    }

    expiresIn = Math.abs(Number(expiresIn));

    if (expiresIn < now) {
      // Expired
      removeStorage(key);
      return null;
    }
  }

  try {
    const value = localStorage.getItem(key) as string;

    // if callback is "json"
    if (json === true) {
      return value ? JSON.parse(value) : null;
    }

    if (boolean === true) {
      if (['1', 'true', 'True', 'TRUE', 1, true].includes(value)) {
        return true;
      }

      if (['0', 'false', 'False', 'FALSE', 0, false].includes(value)) {
        return false;
      }

      return false;
    }

    return value;
  } catch (err) {
    console.warn(
      `getStorage: Error reading key [${key}] from localStorage: ${err}`
    );
    return null;
  }
}

/*  setStorage: writes a key into localStorage setting an expire time
	params:
		key <string>     : localStorage key
		value <string | object | any[]>   : localStorage value
		expires <number> : number of seconds from now to expire the key
	returns:
		<boolean> : telling if operation succeeded
 */
export function setStorage(
  key: string,
  value: string | object | any[],
  expires?: number
): boolean {
  try {
    if (isPlainObject(value) || isArray(value)) {
      value = JSON.stringify(value);
    }

    localStorage.setItem(key, value as any);

    if (expires !== undefined && expires !== null) {
      const now = Date.now(); // millisecs since epoch time, lets deal only with integer
      const schedule = now + expires * 1000;

      localStorage.setItem(key + '_expiresIn', schedule.toString());
    }
  } catch (err) {
    console.warn(
      `setStorage: Error setting key [${key}] in localStorage: ${err}`
    );
    return false;
  }
  return true;
}
